<section class="cr-activities">
    <div class="title">
        <cr-title-bar [label]="text.title" icon="icon-cr-mc-rocket"></cr-title-bar>
        <span class="icon-cr-add" (click)="isAdd = true" *ngIf="!isAdd"></span>
        <div class="poi-selection">
            <cr-dropdown
                    class="header-item"
                    [label]="text.selectPoi + defaultType"
                    [menuItems]="filteredPoi"
                    displayKey="name"
                    placeholder="-"
                    (onSelect)="onSelectPoi($event.$event.model[0])"
                    mode="select"
                    *ngIf="isAdd"
            ></cr-dropdown>
            <span class="icon-cr-clear" (click)="isAdd = false" *ngIf="isAdd"></span>
        </div>
    </div>

    <div class="cr-tabset">
        <tabset>
            <tab [heading]="text.lifts" id="lift-tab"
                 [active]="currentTabId === text.lift"
                 (selectTab)="setTab('lift')">
                <cr-list
                        *ngIf="!dataError"
                        extensionId="internalLift"
                        [timezone]="timezone"
                        [poiList]="poiList"
                        [savePoiFunction]="savePoiFunction"
                ></cr-list>
            </tab>
            <tab [heading]="text.trails" id="trail-tab"
                 [active]="currentTabId === text.trail"
                 (selectTab)="setTab('trail')">
                <cr-list
                        *ngIf="!dataError"
                        extensionId="internalTrail"
                        [timezone]="timezone"
                        [poiList]="poiList"
                        [savePoiFunction]="savePoiFunction"
                        (refresh)="this.ngOnInit()"
                ></cr-list>
            </tab>
            <tab [heading]="text.activities" id="activity-tab"
                 [active]="currentTabId === text.activity"
                 (selectTab)="setTab('activity')">
                <cr-list
                        *ngIf="!dataError"
                        extensionId="internalActivity"
                        [timezone]="timezone"
                        [poiList]="poiList"
                        [savePoiFunction]="savePoiFunction"
                ></cr-list>
            </tab>
            <span class="icon-cr-print" (click)="exportTrailsData()" *ngIf="state.params.tabId === 'trail'"></span>
        </tabset>
    </div>

    <div *ngIf="isLoading || isSaving" class="preloader">
        <cr-spinner class="spinner" smallSpinner="true"></cr-spinner>
    </div>

    <cr-data-error *ngIf="dataError" [message]="text.genericError"></cr-data-error>
    <cr-data-error *ngIf="accessError" [message]="text.notAuthorizeError"></cr-data-error>
</section>

